import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeA from '../../components/Heroes/HeroTypeA';
import LocationMap from '../../components/Location/LocationMap';
import StoreFacts from '../../components/Location/StoreFacts';
import HowToOrderOnline from '../../components/Banners/HowToOrderOnline';
import CategorySection from '../../components/Category Section/CategorySection';
import BannerInfoTypeA from '../../components/Banners/BannerInfoTypeA';
// SUPER HEADER CUSTOM
import BannerTypeA from '../../components/Banners/BannerTypeA';
import LocationSearchSection from '../../components/Location/LocationSearchSection';
import FaqSection from '../../components/FAQ/FaqSection';
import SEO from '../../components/SEO/SEO';

export default function TorontoLocationChinatown({ data }) {
  const chinatown = data.strapiLocations;
  const hero_type_a = chinatown.Hero;
  const hours_of_op = chinatown.Hours_Of_Op;
  const quick_store_facts = chinatown.Store_Facts;
  const category = chinatown.Category_Section;
  const info_banner_type_a = chinatown.Info_Banner_Type_A;
  const banner_type_middle = chinatown.Blog_Banner_Middle;
  const location_search =
    data.strapiLocationsSearchComponent.Location_With_Search;
  const faq = chinatown.FAQ;
  const banner_type_bottom = chinatown.Blog_Banner_Bottom;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Store',
    name: 'House of Cannabis',
    image: '/logo.jpg',
    '@id': '',
    url: 'https://thehousecannabis.ca/toronto/location',
    telephone: '(519)-714-0420',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '404 Spadina Avenue',
      addressLocality: 'Toronto',
      addressRegion: 'ON',
      postalCode: 'M5T 2G7',
      addressCountry: 'CA',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 43.6559931,
      longitude: -79.4018524,
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        opens: '10:00',
        closes: '21:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Sunday',
        opens: '11:00',
        closes: '18:00',
      },
    ],
  };

  return (
    <>
      <SEO
        title="Toronto’s #1 Cannabis Store in Chinatown | House of Cannabis"
        description="Located at 404 Spadina Avenue, our staff takes pride in providing 
                    the best cannabis customer service around. If you have cannabis questions, we have answers!"
        schemaMarkup={schema}
      />
      <HeroTypeA
        title={hero_type_a.Title}
        buttonLabel={"SEE WHAT'S IN STORE"}
        heroImage={
          hero_type_a.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
        buttonLink="/menu"
        hasGradient
      />

      <LocationMap
        title={hours_of_op.Title}
        street={hours_of_op.Address}
        hours={hours_of_op.Day_And_Hours}
        orderSlug={hours_of_op.Order_Online_Link_Slug}
        exactAddress={hours_of_op.Address_Link}
        long={hours_of_op.long}
        lat={hours_of_op.lat}
      />

      <StoreFacts
        storeFacts={quick_store_facts}
        storeHours={hours_of_op.Day_And_Hours}
      />

      <HowToOrderOnline loc_slug="/menu" />

      <CategorySection categorydata={category.Block} title={category.Title} />

      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />

      <BannerTypeA
        title={banner_type_middle.Article_Link.Title}
        description={banner_type_middle.Article_Link.Preview_Text}
        bannerImage={
          banner_type_middle.Article_Link.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/menu"
        buttonLabel="READ ARTICLE"
      />

      {/*        {`/blog/${banner_type_middle.Article_Link.slug}`} */}

      <LocationSearchSection
        titles={location_search.Title}
        locations={location_search.Location_Single}
      />

      <FaqSection faqdata={faq.FAQs} title={faq.Title} />

      <BannerTypeA
        title={banner_type_bottom.Title}
        description={banner_type_bottom.Content}
        bannerImage={
          banner_type_bottom.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/blog/"
        buttonLabel="SEE MORE BLOGS"
      />
    </>
  );
}

export const query = graphql`
  query {
    strapiLocations(id: { eq: "Locations_1" }) {
      Hero {
        Title
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      Store_Facts {
        Contact_Email
        Contact_Phone
        Location_Adress
        Location_City
      }

      Hours_Of_Op {
        Day_And_Hours {
          Day
          Hours
        }
        Address
        Address_Link
        Order_Online_Link_Slug
        Title
        long
        lat
      }

      Category_Section {
        Title
        Block {
          Title
          Link_Slug
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }

      Info_Banner_Type_A {
        Content
        Title
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      Blog_Banner_Middle {
        Article_Link {
          Preview_Text
          Title
          slug
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }

      FAQ {
        FAQs {
          Answer
          Question
        }
        Title
      }

      Blog_Banner_Bottom {
        Content
        Title
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    strapiLocationsSearchComponent {
      Location_With_Search {
        Title
        Location_Single {
          Link_Slug
          Location_Address
          Location_Subtitle
          Location_Title
          Monday_To_Saturday_Hours
          Sunday_Hours
        }
      }
    }
  }
`;
